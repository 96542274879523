import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Statistic } from 'antd';
import * as React from 'react';
import Layout from '../components/Layout';

import createPrivatePage from '../utils/createPrivatePage';

const IndexPage = () => {
  return (
    <Layout
    // pageTitle="Dashboard"
    // breadcrumb={[
    //   { title: 'Home', path: '/' },
    //   { title: 'Dashboard', path: '/' },
    // ]}
    >
      {/* <Row gutter={16}>
        <Col span={12}>
          <Card>
            <Statistic
              title="Active Users"
              value={112893}
              prefix={<ArrowDownOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Account Balance (CNY)"
              value={112893}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix={<ArrowUpOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
      </Row> */}
    </Layout>
  );
};

export default createPrivatePage(IndexPage);
